<template>
    <VcLayout @click="$emit('click', $event)" class="campaign-item-container" justify-start>
      <VcLayout class="campaign-details-container" align-start>
        <div v-if="imagePath"
             class="campaign-image"
             :style="{ backgroundImage: `url(${imagePath})` }"
             role="img"
             aria-label="Campaign image"/>
        <VcImage v-else class="campaign-no-image" :image="defaultImage"/>
        <div class="campaign-data-container ps-3 align-content-center">
          <div class="campaign-title-text ellipsis pb-2">{{title}}</div>
          <VcBadge class="campaign-badge pb-2" :badgeText="campaignTypeText" flavor="neutral" inline/>
          <div class="campaign-date-text ellipsis">
            <VcIcon size="16px" class="me-2" color="gray-darken-5">icon-Marketing_POV</VcIcon>
            <span>{{campaignSentAtText}}</span>
          </div>
        </div>
      </VcLayout>
      <VcLayout v-if="formattedStats.length" class="campaign-stats-container" align-end align-content-center>
          <div v-for="(count, label) in formattedStats" :key="label" :data-stat-type="count.key" class="campaign-stat-item">
            <p class="stat-value mb-0">{{ count.value }}</p>
            <p class="stat-label ellipsis">{{ count.label }}</p>
          </div>
        </VcLayout>
    </VcLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import campaignImage from '@/assets/images/widgets/no_image.svg';

export default {
  name: 'CampaignItem',
  data() {
    return {
      defaultImage: campaignImage,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    imagePath: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'manual',
    },
    date: {
      type: String,
      default: '',
    },
    stats: {
      type: Object,
    },
    columnsCount: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    ...mapGetters('BusinessStore', ['primaryColor', 'textColor']),
    campaignTypeText() {
      const campaignType = (this.type === 'manual') ? this.$t('widget.campaigns_widget.manual') : this.$t('widget.campaigns_widget.automatic');
      return campaignType;
    },
    campaignSentAtText() {
      const sentOn = this.$t('widget.campaigns_widget.campaign_sent_at', { date: this.date });
      return sentOn;
    },
    formattedStats() {
      const sentCount = this.stats.sent || 1;
      const statsMap = {
        sent: this.$t('widget.campaigns_widget.sent'),
        viewed: this.$t('widget.campaigns_widget.viewed'),
        clicked: this.$t('widget.campaigns_widget.clicked'),
        engaged: this.$t('widget.campaigns_widget.engaged'),
      };

      return Object.entries(this.stats).map(([key, value]) => {
        const statValue = value || 0;
        const percentage = key !== 'sent' ? ((statValue / sentCount) * 100).toFixed(0) : null;

        return {
          value: statValue.toLocaleString(),
          key,
          label: percentage > 0 ? `${statsMap[key]} ${percentage}%` : statsMap[key],
        };
      });
    },
  },
};
</script>

<style scoped lang="scss">
$xSmall: 359px;
$small: 485px;
$medium: 598px;
$large: 684px;
.campaign-item-container {
  cursor: pointer;
  container-type: inline-size;
  width: 100%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: var(--border-radius);
  }

  .campaign-details-container{
    align-items: center;
    justify-content: flex-start;
      .campaign-no-image {
        flex: unset;
      }
      .campaign-image {
        width: 80px;
        height: 80px;
        background-size: cover;
        background-position: center;
        border-radius: var(--size-value1);
        border: 1px solid var(--gray-lighten-1);
      }
    .campaign-data-container{
      padding-block: var(--size-value1);
      color: var(--gray-darken-5);
      width: 218px;
      flex: 1 1 0;

      @container (max-width: #{$medium}) {
        overflow: hidden;
      }

      .campaign-title-text {
        font-size: var(--font-size-small2);
        font-weight: var(--font-weight-medium2);
        max-width: 100%;
      }
      .campaign-badge {
        font-weight: var(--font-weight-large2);
      }
      ::v-deep {
        .v-badge--inline .v-badge__wrapper {
          margin: 0;
        }
      }
      .campaign-date-text {
        font-size: var(--font-size-xx-small);
        font-weight: var(--font-weight-medium);
      }
    }
  }

  .campaign-stats-container {
    text-align: center;
    justify-content: space-between;

    @container (max-width: #{$small}) {
      justify-content: flex-end;
    }

    .campaign-stat-item {
      align-content: center;
      min-width: 90px;
      display: none;

      @container (min-width: #{$xSmall}) and (max-width: #{$small}) {
        &[data-stat-type='engaged'] {
          display: block;
        }
      }

      @container (min-width: #{$small}) and (max-width: #{$medium}) {
        &[data-stat-type='sent'],
        &[data-stat-type='engaged'] {
          display: block;
        }
      }

      @container (min-width: #{$medium}) and (max-width: #{$large}) {
        &[data-stat-type='sent'],
        &[data-stat-type='clicked'],
        &[data-stat-type='engaged'] {
          display: block;
        }
      }

      @container (min-width: #{$large}) {
        &[data-stat-type='sent'],
        &[data-stat-type='clicked'],
        &[data-stat-type='engaged'],
        &[data-stat-type='viewed'] {
          display: block;
        }
      }
    }
    .stat-value {
      font-size: var(--font-size-medium);
      font-weight: var(--font-weight-large2);
    }

    .stat-label {
      color: var(--gray-darken-4);
      font-size: var(--font-size-xx-small);
      font-weight: var(--font-weight-large);
    }
  }
  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

</style>
