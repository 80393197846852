import httpService from '@/modules/common/services/httpService';

const baseRoute = '/business/staffs/v2/staffs';
const baseRouteV1 = '/business/staffs/v1/staffs';
const baseRouteV3 = 'v3/staff';

const fetchStaffData = async (staffUid, businessUid) => httpService.get(`/platform/v1/businesses/${businessUid}/staffs/${staffUid}`)
  .then((res) => res.data?.data?.staff[0]);

const fetchQuickActions = async (showOnlyVisible) => httpService.get(`${baseRouteV3}/staff_quick_action_lists`, { show_only_visible: showOnlyVisible }).then((res) => res.data);

const updateQuickActions = async (quickActions, staffUid) => httpService.put(`${baseRouteV3}/staff_quick_action_lists/${staffUid}`, quickActions).then((res) => res.data);

const fetchPermissions = async (staffUid) => httpService.get(`${baseRoute}/${staffUid}/permissions`)
  .then((res) => {
    const response = res.data?.data?.permissions;
    const permissions = Object.keys(response).filter((permission) => response[permission]);
    return permissions;
  });

const toggleStaffCrmSettings = async (staffUid) => httpService.post(`${baseRouteV1}/${staffUid}/toggle_staff_crm_settings`)
  .then((res) => res.data?.data);

const fetchAllStaff = async () => httpService.get('/v2/staffs')
  .then((res) => res.data?.data);

const setDefaultPage = async (staffUid, defaultPage) => {
  try {
    const response = await httpService.put(`/v2/staffs/${staffUid}`, {
      on_entry_navigate_to: defaultPage,
    });
    return response.data?.data;
  } catch (error) {
    // Handle network errors or other unexpected errors
    console.error('error', error);
    throw new Error('Failed to update default page. Please try again later.');
  }
};

const updateSkipTodoFlag = async (businessUid, skipTodoPage) => httpService.put(`/v2/business_flags/${businessUid}`, { skip_todo_page: skipTodoPage })
  .then((res) => res.data);

// eslint-disable-next-line import/prefer-default-export
export const staffService = {
  fetchPermissions,
  fetchStaffData,
  fetchAllStaff,
  toggleStaffCrmSettings,
  fetchQuickActions,
  updateQuickActions,
  updateSkipTodoFlag,
  setDefaultPage,
};
